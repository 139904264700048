//工单列表
const showWorkOrderListUrl = `/gateway/hc-serve/manageapi/snapshot/showWorkOrderList`;
//删除工单
const removeWorkOrderUrl = `/gateway/hc-serve/manageapi/snapshot/removeWorkOrder`;
//转派工单
const redeployWorkOrderUrl = `/gateway/hc-serve/manageapi/snapshot/redeployWorkOrder`;
//工单详情
const workOrderDetailsUrl = `/gateway/hc-serve/manageapi/snapshot/workOrderDetails`;
//工单类型列表
const workOrderTypeListUrl = `/gateway/hc-serve/manageapi/snapshot/workOrderTypeList`;
//工单类型详情
const workOrderTypeDetailsUrl = `/gateway/hc-serve/manageapi/snapshot/workOrderTypeDetails`;
//工单类型新增
const addWorkOrderTypeUrl = `/gateway/hc-serve/manageapi/snapshot/addWorkOrderType`;
//工单类型编辑
const workOrderTypeCompileUrl = `/gateway/hc-serve/manageapi/snapshot/workOrderTypeCompile`;
//工单类型删除
const removeWorkOrderTypeUrl = `/gateway/hc-serve/manageapi/snapshot/removeWorkOrderType`;
//工单问题删除
const removeWorkOrderProblemUrl = `/gateway/hc-serve/manageapi/snapshot/remove-work-order-problem`;

//事件类型列表
const incidentTypeListUrl = `/gateway/hc-serve/manageapi/snapshot/incidentTypeList`;
// 事件类型删除
const delIncidentTypeUrl = `/gateway/hc-serve/manageapi/snapshot/delIncidentType`;
// 事件类型添加
const addIncidentTypeUrl = `/gateway/hc-serve/manageapi/snapshot/addIncidentType`;

// 受理人列表
const showReceiverListUrl = `/gateway/hc-serve/manageapi/snapshot/showReceiverList`;
// 可添加的楼幢列表
const showServingBuildingListUrl = `/gateway/hc-serve/manageapi/snapshot/showServingBuildingList`;
// 受理人新增
const addReceiverUrl = `/gateway/hc-serve/manageapi/snapshot/addReceiver`;
// 受理人修改
const receiverCompileUrl = `/gateway/hc-serve/manageapi/snapshot/receiverCompile`;
// 删除受理人
const removeCompileUrl = `/gateway/hc-serve/manageapi/snapshot/removeReceiver`;
// 小区列表
const communityListUrl = `/gateway/hc-space/space/getSpaceTreeList`;
// 楼栋列表
const servingBuildingListUrl = `/gateway/hc-serve/manageapi/snapshot/servingBuildingList`;
// 可转派的受理人列表
const redeployReceiverListUrl = `/gateway/hc-serve/manageapi/snapshot/redeployReceiverList`;
// 转派记录列表
const redeployRecordListUrl = `/gateway/hc-serve/manageapi/snapshot/redeployRecordList`;
// 获取工单事件类型树
const getIncidentTypeTreeUrl = `/gateway/hc-serve/manageapi/snapshot/getIncidentTypeTree`;
// 编辑手机号
const receiverPhoneEdit = `/gateway/hc-serve/manageapi/snapshot/receiverPhoneEdit`;
//获取建筑列表
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;

export {
  showWorkOrderListUrl,
  removeWorkOrderUrl,
  redeployWorkOrderUrl,
  workOrderDetailsUrl,
  workOrderTypeListUrl,
  workOrderTypeDetailsUrl,
  incidentTypeListUrl,
  addWorkOrderTypeUrl,
  workOrderTypeCompileUrl,
  removeWorkOrderTypeUrl,
  removeWorkOrderProblemUrl,
  showReceiverListUrl,
  showServingBuildingListUrl,
  addReceiverUrl,
  receiverCompileUrl,
  removeCompileUrl,
  communityListUrl,
  servingBuildingListUrl,
  redeployReceiverListUrl,
  redeployRecordListUrl,
  delIncidentTypeUrl,
  addIncidentTypeUrl,
  getIncidentTypeTreeUrl,
  getBuildListURL,
  receiverPhoneEdit,
};
